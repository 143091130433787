<template>
  <div class="card" ref="templateBody">
    <div class="card-body">
      <section class="admin-main-content">
        <div class="admin-content-header">
          <h2>
            청약 제품 상세보기
          </h2>
        </div>
        <div class="col-12">
          <div class="card card-default">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>청약 이벤트명</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      v-model="subscription.title"
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label>청약 추첨 알고리즘</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      v-model="subscription.subscribe_algorithm_nm"
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label>추첨수 / 선착순 수</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      v-model="subscription.subscribe_count"
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label>노출여부</label>
                    <select
                      class="form-control"
                      v-model="subscription.is_public"
                      disabled
                    >
                      <option value="true">노출</option>
                      <option value="true">미노출</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label>이벤트 상태</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      v-model="subscription.progress_status_nm"
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label>일반가격</label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder=""
                      v-model="subscription.normal_price"
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label>이벤트가격</label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder=""
                      v-model="subscription.event_price"
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label>이벤트게시 일시</label>
                    <input
                      type="datetime-local"
                      class="form-control"
                      placeholder=""
                      v-model="subscription.publish_date"
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label>청약이벤트 기간</label>
                    <div class="row">
                      <div class="col-md-6">
                        <input
                          type="datetime-local"
                          class="form-control"
                          placeholder=""
                          v-model="subscription.event_start_at"
                          disabled
                        />
                      </div>
                      <div class="col-md-6">
                        <input
                          type="datetime-local"
                          class="form-control"
                          placeholder=""
                          v-model="subscription.event_end_at"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>이벤트게시 일시</label>
                    <input
                      type="datetime-local"
                      class="form-control"
                      placeholder=""
                      v-model="subscription.publish_date"
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label>청약 추첨 후 결제 마감 시간 간격</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      v-model="subscription.payment_closing_term"
                      disabled
                    />
                  </div>
                  <div
                    class="form-group d-flex flex-row justify-content-between"
                  >
                    <label
                      >신청자 목록 - {{ subscriptionUserList.length }}명</label
                    >
                    <xlsx-workbook>
                      <xlsx-sheet
                        v-for="sheet in sheets"
                        :collection="sheet.data"
                        :key="sheet.name"
                        :sheet-name="sheet.name"
                      />
                      <button class="btn btn-primary" @click="download">
                        전체 신청자 다운로드
                      </button>
                      <xlsx-download :filename="sheetName">
                        <button
                          ref="downloadBtn"
                          class="btn btn-primary"
                          style="display: none;"
                        >
                          발주서 다운로드
                        </button>
                      </xlsx-download>
                    </xlsx-workbook>
                  </div>
                  <div
                    class="form-group d-flex flex-row justify-content-between"
                  >
                    <label
                      >이벤트 추첨 - {{ subscription.prize_status_nm }}</label
                    >
                    <button
                      v-if="
                        subscription.prize_status === 'PRIZE_PROGRESS' ||
                          subscription.prize_status === 'PRIZE_CLOSED'
                      "
                      class="btn btn-primary"
                      @click="openUserModal"
                    >
                      당첨자확인
                    </button>
                    <button v-else class="btn btn-primary" @click="setPrize">
                      추첨진행
                    </button>
                  </div>
                  <div class="form-group">
                    <label
                      >옵션
                      {{ subscription.is_with_option ? '' : '없음' }}</label
                    >
                  </div>
                  <div v-if="subscription.is_with_option" class="form-group">
                    <table
                      class="table table-responsive-sm table-bordered table-striped table-sm"
                    >
                      <thead>
                        <tr class="text-center">
                          <th colspan="3">옵션명</th>
                          <th rowspan="2" width="100">사용여부</th>
                        </tr>
                        <tr class="text-center">
                          <th>{{ subscription.option_meta.option_1?.key }}</th>
                          <th>{{ subscription.option_meta.option_2?.key }}</th>
                          <th>{{ subscription.option_meta.option_3?.key }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(option, index) in subscription.options"
                          :key="`option${index}`"
                        >
                          <td>{{ option.option_1 }}</td>
                          <td>{{ option.option_2 }}</td>
                          <td>{{ option.option_3 }}</td>
                          <td>
                            <label
                              class="c-switch c-switch-pill c-switch-primary"
                            >
                              <input
                                type="checkbox"
                                class="c-switch-input"
                                v-model="option.is_public"
                                disabled
                              />
                              <span class="c-switch-slider"></span>
                            </label>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="form-group">
                    <label>비고</label>
                    <textarea
                      class="form-control"
                      placeholder=""
                      v-model="subscription.remark"
                      disabled
                    ></textarea>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>이벤트 제품명</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      v-model="subscription.product_name"
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label>제품 브랜드</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      v-model="subscription.brand"
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label>반품/교환 주소</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      v-model="subscription.brand_address"
                      disabled
                    />
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      v-model="subscription.brand_address_detail"
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label>판매제품 문의처</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      v-model="subscription.brand_contact"
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label
                      >청약 Content Url
                      <button
                        class="btn btn-primary"
                        @click="
                          copyUrl(`https://sgma.io/event-mall?dataId=${id}`)
                        "
                      >
                        Url 복사하기
                      </button></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      :value="`https://sgma.io/event-mall?dataId=${id}`"
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label>썸네일 이미지</label>
                    <img
                      ref="thumbnailImg"
                      class="thumbnail-img"
                      v-show="showThumbnailImage"
                      :src="subscription.thumbnail_url"
                      alt=""
                    />
                  </div>
                  <div class="form-group">
                    <label>상품 상세 이미지</label>
                    <div class="d-flex flex-column">
                      <img
                        class="detail-img"
                        v-for="(image, index) in subscription.images"
                        :key="`image${index}`"
                        :src="image.image_url"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer align-items-center">
              <div class="footer-btn-wrapper">
                <button
                  v-if="subscription.prize_status === 'PRIZE_PROGRESS'"
                  type="submit"
                  class="btn btn-lg btn-primary"
                  @click="closeEvent"
                >
                  이벤트마감
                </button>
                <button
                  type="submit"
                  class="btn btn-lg btn-default"
                  @click="back"
                >
                  목록
                </button>
                <button
                  type="submit"
                  class="btn btn-lg btn-danger"
                  @click="deleteEvent"
                >
                  삭제
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div
      class="modal modal_background fade show"
      ref="userModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      @click="closeModal($event, false)"
    >
      <div class="modal-dialog modal-xl prize-modal" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="userModalLabel">당첨자 목록</h5>
            <button class="btn btn-primary ml-1 mr-1" @click="refreshPrizeUser">
              당첨자 정보 새로고침
            </button>
            <xlsx-workbook>
              <xlsx-sheet
                v-for="sheet in prizeSheets"
                :collection="sheet.data"
                :key="sheet.name"
                :sheet-name="sheet.name"
              />
              <button class="btn btn-primary" @click="downloadPrize">
                당첨자 엑셀 다운로드
              </button>
              <xlsx-download :filename="prizeSheetName">
                <button
                  ref="prizeDownloadBtn"
                  class="btn btn-primary"
                  style="display: none;"
                ></button>
              </xlsx-download>
            </xlsx-workbook>
            <div v-if="subscription.option_meta?.option_1" class="col-md-1">
              <select
                class="form-control"
                v-model="option1"
                :placeholder="subscription.option_meta?.option_1.key"
                @change="filterOption"
              >
                <option value=""
                  >{{ subscription.option_meta?.option_1.key }} 전체</option
                >
                <option
                  v-for="option in option1List"
                  :value="option"
                  :key="`option1-${option}`"
                >
                  {{ option }}
                </option>
              </select>
            </div>
            <div v-if="subscription.option_meta?.option_2" class="col-md-1">
              <select
                class="form-control"
                v-model="option2"
                :placeholder="subscription.option_meta?.option_2.key"
                @change="filterOption"
              >
                <option value=""
                  >{{ subscription.option_meta?.option_2.key }} 전체</option
                >
                <option
                  v-for="option in option2List"
                  :value="option"
                  :key="`option2-${option}`"
                >
                  {{ option }}
                </option>
              </select>
            </div>
            <div v-if="subscription.option_meta?.option_3" class="col-md-1">
              <select
                class="form-control"
                v-model="option3"
                :placeholder="subscription.option_meta?.option_3.key"
                @change="filterOption"
              >
                <option value=""
                  >{{ subscription.option_meta?.option_3.key }} 전체</option
                >
                <option
                  v-for="option in option3List"
                  :value="option"
                  :key="`option3-${option}`"
                >
                  {{ option }}
                </option>
              </select>
            </div>
            <!--            <div class="col-md-1">-->
            <!--              <select class="form-control" v-model="option3" :placeholder="subscription.option_meta?.option_3.key"  @change="filterOption">-->
            <!--                <option value="">당첨자 전체</option>-->
            <!--                <option value="READY">추첨대기</option>-->
            <!--                <option value="BEAT">당첨(결제대기)</option>-->
            <!--                <option value="COMPLETE">결제완료</option>-->
            <!--                <option value="CANCEL">청약취소</option>-->
            <!--                <option value="OUTSTANDING">미결제취소</option>-->
            <!--              </select>-->
            <!--            </div>-->
            <!--            <div class="col-md-1">-->
            <!--              <select class="form-control" v-model="option3" :placeholder="subscription.option_meta?.option_3.key"  @change="filterOption">-->
            <!--                <option value="">당첨자 전체</option>-->
            <!--                <option value="READY">추첨대기</option>-->
            <!--                <option value="BEAT">당첨(결제대기)</option>-->
            <!--                <option value="COMPLETE">결제완료</option>-->
            <!--                <option value="CANCEL">청약취소</option>-->
            <!--                <option value="OUTSTANDING">미결제취소</option>-->
            <!--              </select>-->
            <!--            </div>-->
            <button
              v-if="subscription.option_meta?.option_1"
              class="btn btn-primary ml-1 mr-1"
              @click="resetFilter"
            >
              필터 초기화
            </button>
            <button
              v-if="showPrizeUser"
              class="btn btn-danger ml-1 mr-1"
              @click="filterPrize"
            >
              전체보기
            </button>
            <button
              v-else
              class="btn btn-primary ml-1 mr-1"
              @click="filterPrize"
            >
              당첨자만보기
            </button>
            <button class="btn btn-primary ml-3 mr-3" @click="prizeCheckedUser">
              선택 당첨자선정
            </button>
            <input
              ref="fileBtn"
              style="display: none;"
              type="file"
              @change="fileUploaded"
            />
            <button class="btn btn-primary mr-3" @click="upload">
              배송정보 업로드
            </button>
            <button class="btn btn-primary mr-3" @click="updateCheckDelivery">
              배송정보 일괄 처리
            </button>
            <button class="btn btn-primary mr-3" @click="updateInvoice">
              배송정보 일괄 수정
            </button>
            <button
              v-if="subscription.prize_status === 'PRIZE_CLOSED'"
              class="btn btn-primary ml-10"
              @click="showDeliveryMessage"
            >
              배송안내 메시지
            </button>
            <xlsx-read :file="file">
              <xlsx-json @parsed="onParsed"> </xlsx-json>
            </xlsx-read>
            <!--            <div class="form-group">-->
            <!--              <select class="form-control" placeholder="택배사코드" @change="setAllDelivery($event)">-->
            <!--                <option value="">택배사 일괄선택</option>-->
            <!--                <option value="CJGLS">CJ대한통운</option>-->
            <!--                <option value="KGB">로젠택배</option>-->
            <!--                <option value="EPOST">우체국택배</option>-->
            <!--                <option value="HANJIN">한진택배</option>-->
            <!--                <option value="HYUNDAI">롯데택배</option>-->
            <!--                <option value="KDEXP">경동택배</option>-->
            <!--              </select>-->
            <!--            </div>-->
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="closeModal($event, true)"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-header">
            - 당첨자 : {{ beatCount }} - 청약취소: {{ cancelCount }} - 결제시간
            초과: {{ outstandingCount }} - 결제완료 {{ completeCount }}
          </div>
          <div class="modal-body">
            <div class="table-responsive">
              <table
                class="table table-responsive-sm table-bordered table-striped table-sm"
              >
                <thead>
                  <tr>
                    <th class="text-center" width="50" @click="checkAll">
                      전체<br />
                      <input type="checkbox" v-model="isAllCheck" />
                    </th>
                    <th class="text-center" width="50">추첨순번</th>
                    <th class="text-center">상태</th>
                    <th class="text-center">닉네임</th>
                    <th class="text-center">이름</th>
                    <th class="text-center">등급</th>
                    <th class="text-center">연락처</th>
                    <th class="text-center">카카오톡발송여부</th>
                    <th v-if="subscription.option_meta?.option_1">
                      {{ `옵션(${subscription.option_meta?.option_1.key})` }}
                    </th>
                    <th v-if="subscription.option_meta?.option_2">
                      {{ `옵션(${subscription.option_meta?.option_2.key})` }}
                    </th>
                    <th v-if="subscription.option_meta?.option_3">
                      {{ `옵션(${subscription.option_meta?.option_3.key})` }}
                    </th>
                    <th class="text-center">수령인이름</th>
                    <th class="text-center">주소</th>
                    <th class="text-center">청약일시</th>
                    <th class="text-center">비고</th>
                    <th class="text-center" col="100">업데이트</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="user in filteredData"
                    :key="user.data_id"
                    :class="{
                      'cancel-line':
                        user.status === 'CANCEL' ||
                        user.status === 'OUTSTANDING',
                    }"
                  >
                    <th class="text-center" width="50">
                      <input type="checkbox" v-model="user.isCheck" />
                    </th>
                    <td class="text-center">
                      {{ user.subscription_idx }}
                    </td>
                    <td class="text-center">
                      {{ user.status_nm }}
                    </td>
                    <td class="text-center">
                      {{ user.nick_name }}
                    </td>
                    <td class="text-center">
                      {{ user.name }}
                    </td>
                    <td class="text-center">
                      <img
                        :src="user.user_score?.tier?.tierImageUrl"
                        alt=""
                        width="40"
                      />
                    </td>
                    <td class="text-center">
                      {{ user.cell_num }}
                    </td>
                    <td class="text-center">
                      {{ user.message_send_at ? user.message_send_at : ' - ' }}
                    </td>
                    <th v-if="subscription.option_meta?.option_1">
                      {{ user.options?.option_1 }}
                    </th>
                    <th v-if="subscription.option_meta?.option_2">
                      {{ user.options?.option_2 }}
                    </th>
                    <th v-if="subscription.option_meta?.option_3">
                      {{ user.options?.option_3 }}
                    </th>
                    <td class="text-center">
                      {{ user.order_delivery?.name }}
                    </td>
                    <td v-if="user.order_delivery?.address !== ''">
                      {{
                        `${user.order_delivery?.address || ''} ${user
                          .order_delivery?.address_detail || ''}`
                      }}
                    </td>
                    <td v-else class="text-center">
                      -
                    </td>
                    <td class="text-center">
                      {{ user.create_at }}
                    </td>
                    <td v-if="user.status === 'BEAT'" class="text-center">
                      아직 결제가 진행되지 않았습니다.
                    </td>
                    <td v-else-if="user.status === 'COMPLETE'">
                      <select
                        v-model="
                          user.order_delivery.invoices[0].delivery_company_cd
                        "
                        class="form-control"
                        placeholder="택배사코드"
                      >
                        <option value="">택배사를 선택하세요</option>
                        <option value="CJGLS">CJ대한통운</option>
                        <option value="KGB">로젠택배</option>
                        <option value="EPOST">우체국택배</option>
                        <option value="HANJIN">한진택배</option>
                        <option value="HYUNDAI">롯데택배</option>
                        <option value="KDEXP">경동택배</option>
                      </select>
                      <input
                        v-model="user.order_delivery.invoices[0].invoice_number"
                        type="text"
                        class="form-control"
                        placeholder="송장번호"
                      />
                    </td>
                    <td v-else class="text-center">-</td>
                    <td class="text-center">
                      <!-- <button
                        v-if="
                          user.status === 'COMPLETE' &&
                            !user.order_delivery.invoices[0].data_id
                        "
                        class="btn btn-primary"
                        @click="updateInvoice(user)"
                      >
                        배송정보 등록 여기
                      </button>
                      <button
                        v-if="
                          user.status === 'COMPLETE' &&
                            user.order_delivery.invoices[0].data_id
                        "
                        class="btn btn-warning"
                        @click="updateInvoice(user)"
                      >
                        배송정보 수정
                      </button> -->
                      <button
                        v-if="
                          user.status === 'COMPLETE' &&
                            !user.order_delivery.invoices[0].data_id
                        "
                        class="btn btn-danger"
                        @click="cancelPayment(user)"
                      >
                        결제취소
                      </button>
                      <button
                        v-if="user.status === 'READY'"
                        class="btn btn-primary"
                        @click="setAddPrize(user)"
                      >
                        당첨자 선정
                      </button>
                      <button
                        v-if="user.status === 'OUTSTANDING'"
                        class="btn btn-primary"
                        @click="extendTime(user)"
                      >
                        결제시간 연장
                      </button>
                      <button
                        v-if="user.status === 'BEAT'"
                        class="btn btn-primary"
                        @click="depositWithoutPassbook(user)"
                      >
                        무통장 입금처리
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, onUpdated } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { key } from '@/store';
import { ApiService } from '@/services/backend-api/api.services';
import swal from '@/helper/swal.helper';
import commerceUtil from '@/helper/commerceUtil';
import {
  XlsxWorkbook,
  XlsxSheet,
  XlsxDownload,
  XlsxRead,
  XlsxJson,
} from 'vue3-xlsx';
import dateTimeHelper from '@/helper/datetime.helper';

export default {
  name: 'PartnersMallsubscriptionRegister',
  components: {
    XlsxJson,
    XlsxRead,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const store = useStore(key);
    const apiService = new ApiService();
    const { id } = route.params;
    const isDetail = ref(true);
    const thumbnailFile = ref(null);
    const thumbnailImg = ref(null);
    const showThumbnailImage = ref(false);
    const detailFile = ref(null);
    const detailImg = ref(null);
    const detailImageList = ref([]);
    const subscription = ref({
      data_id: '04e6b72f-dd5c-4969-a4dc-d159eb9dbf4c',
      title: '이벤트 제목',
      subscription_name: '다이와 코리아 SEABORG 200J',
      is_public: true,
      publish_date: '2023-06-19 20:00:00',
      event_start_at: '2023-06-19 20:00:00',
      event_end_at: '2023-06-19 20:00:00',
      payment_closing_term: '20:00:00',
      normal_price: '100000',
      event_price: '80000',
      subscribe_algorithm: 'RANDOM',
      subscribe_algorithm_nm: '랜덤',
      subscribe_count: 10,
      read_cnt: '3',
      thumbnail_url:
        'https://cdn.uhshin.com/development/partners/4551400828/master/63bb7af6-796b-464b-a51e-d86aa7d0f226.jpeg',
      create_at: '2023-06-19 20:15:42',
      remark: '비고내용',
      progress_status: 'READY',
      product_name: '',
      progress_status_nm: '준비',
      brand: '브랜드',
      brand_address: '',
      brand_address_detail: '',
      brand_contact: '',
      is_subscribe_close: false,
      purchasable_tier: {
        data_id: '04e6b72f-dd5c-4969-a4dc-d159eb9dbf4c',
        tier_id: '6373271ab89d39de72d31f48',
        code: 'RED_FISH',
        code_name: '레드피쉬',
        max_exp: 50,
        image_url:
          'https://cdn.uhshin.com/development/partners/1042899847/attach/2a30100c-67a2-4add-8571-e19cf042ca52.png',
      },
      image_urls: [
        'https://cdn.uhshin.com/development/partners/1042899847/attach/2a30100c-67a2-4add-8571-e19cf042ca52.png',
        'https://cdn.uhshin.com/development/partners/1042899847/attach/2a30100c-67a2-4add-8571-e19cf042ca53.png',
      ],
    });
    const option1List = ref([]);
    const option2List = ref([]);
    const option3List = ref([]);
    const option1 = ref('');
    const option2 = ref('');
    const option3 = ref('');
    const downloadBtn = ref(null);
    const prizeDownloadBtn = ref(null);
    const userModal = ref(null);
    const templateBody = ref(null);
    const subscriptionUserList = ref([]);
    const subscriptionPrizeList = ref([]);
    const filteredData = ref([]);
    const sheet = ref([]);
    const sheets = ref([]);
    const sheetName = ref('청약신청자.xlsx');
    const prizeSheet = ref([]);
    const prizeSheets = ref([]);
    const prizeSheetName = ref('청약신청자.xlsx');
    const hasPrize = ref(false);
    const showPrizeUser = ref(false);
    // 미결재 카운트
    const outstandingCount = ref(0);
    // 청약 취소 카운트
    const cancelCount = ref(0);
    let prizingCount = 0;
    const beatCount = ref(0);
    const completeCount = ref(0);

    const fileBtn = ref(null);
    const file = ref(null);
    const isAllCheck = ref(false);
    onMounted(async () => {
      store.commit('loading/showLoading', '데이터를 불러오는중입니다.');
      const subscriptionData = await getSubscription(id);
      console.log(subscriptionData);
      if (subscriptionData.option_meta?.option_1) {
        option1List.value = subscriptionData.option_meta?.option_1.value.split(
          ',',
        );
      }
      if (subscriptionData.option_meta?.option_2) {
        option2List.value = subscriptionData.option_meta?.option_2.value.split(
          ',',
        );
      }
      if (subscriptionData.option_meta?.option_3) {
        option3List.value = subscriptionData.option_meta?.option_3.value.split(
          ',',
        );
      }
      subscription.value = subscriptionData;
      if (
        subscriptionData.prize_status === 'PRIZE_PROGRESS' ||
        subscriptionData.prize_status === 'PRIZE_CLOSED'
      ) {
        await setPrizeUser();
      }
      const subscriptionUserData = await getSubscriptionUser(id);
      showThumbnailImage.value = true;
      subscriptionUserList.value = subscriptionUserData;
      setSheet(subscriptionUserData);
      store.commit('loading/hideLoading');
      loadDaumAddress();
    });
    const getSubscription = async id => {
      const response = await apiService.get(
        `/v3/usin-subscription-event/${id}`,
      );
      return response.data.item;
    };
    const getSubscriptionUser = async id => {
      const response = await apiService.get(
        `/v1/usin-subscription-event/${id}/user?page=0&size=10000`,
      );
      return response.data.items;
    };
    const getSubscriptionPrizeUser = async id => {
      // const response = await apiService.get(`/v1/usin-subscription-event/${id}/user?page=0&size=${subscription.value.subscribe_count * 10}`);
      const response = await apiService.get(
        `/v1/usin-subscription-event/${id}/user?page=0&size=10000`,
      );
      return response.data.items;
    };
    const setPrize = async () => {
      if (subscription.value.progress_status !== 'END') {
        swal.alert('청약 이벤트가 마감되지 않았습니다.');
        return false;
      }
      swal.prizeConfirm(async () => {
        store.commit('loading/showLoading', '추첨중입니다.');
        const response = await apiService.patch(
          `/v1/usin-subscription-event/${id}/prize`,
        );
        const subscriptionData = await getSubscription(id);
        subscription.value = subscriptionData;
        await setPrizeUser();
        store.commit('loading/hideLoading');
      });
      return true;
    };

    const back = () => {
      router.back();
    };
    onUpdated(() => {
      setDetailImage();
    });
    const setDetailImage = () => {
      detailImageList.value.forEach((img, index) => {
        const reader = new FileReader();
        const detailImgs = document.querySelectorAll('.add-detail-img');
        reader.onload = e => {
          detailImgs[index].src = e.target.result;
        };
        reader.readAsDataURL(img.imageFile);
      });
    };
    const openUserModal = () => {
      option1.value = '';
      option2.value = '';
      option3.value = '';
      templateBody.value.classList.add('modal-open');
      userModal.value.classList.add('showModal');
    };
    const closeModal = (e, isClose) => {
      if (e.target.classList.contains('modal') || isClose) {
        templateBody.value.classList.remove('modal-open');
        userModal.value.classList.remove('showModal');
      }
    };
    const download = () => {
      swal.dowloadXlsx(downloadXlsx, '청약몰 신청자 목록을 다운로드 합니다.');
    };
    const downloadXlsx = () => {
      downloadBtn.value.click();
    };
    const setSheet = subscriptionUserList => {
      sheet.value = [];
      if (subscriptionUserList.length !== 0) {
        subscriptionUserList.forEach(user => {
          const subscriptionUser = {
            uid: user.uid,
            닉네임: user.nick_name,
            등급: user.user_score?.tier?.tierName,
            전화번호: user.cell_num,
            청약일시: user.create_at,
            가입일자: `${user.signup_hour.year}-${user.signup_hour.month}-${user.signup_hour.day} ${user.signup_hour.hour}:${user.signup_hour.minute}`,
            결제건수: user.total_user_payment_count,
          };
          if (subscription.value.option_meta?.option_1) {
            subscriptionUser[
              `옵션(${subscription.value.option_meta.option_1.key})`
            ] = user.options.option_1;
          }
          if (subscription.value.option_meta?.option_2) {
            subscriptionUser[
              `옵션(${subscription.value.option_meta.option_2.key})`
            ] = user.options.option_2;
          }
          if (subscription.value.option_meta?.option_3) {
            subscriptionUser[
              `옵션(${subscription.value.option_meta.option_3.key})`
            ] = user.options.option_3;
          }
          sheet.value.push(subscriptionUser);
        });
        sheets.value.push({
          name: '신청자',
          data: sheet.value,
        });
        const date = dateTimeHelper.timestampToDateTime(new Date());
        sheetName.value = `${subscription.value.title}-${
          date.split(' ')[0]
        }.xlsx`;
      }
    };
    const downloadPrize = () => {
      swal.dowloadXlsx(downloadPrizeXlsx, '당첨자 목록을 다운로드 합니다.');
    };
    const downloadPrizeXlsx = () => {
      if (prizeSheet.value.length > 0) {
        prizeDownloadBtn.value.click();
      } else {
        swal.alert('당첨자 정보가 없습니다.');
      }
    };
    const setPrizeSheet = () => {
      prizeSheet.value = [];
      if (subscriptionPrizeList.value.length !== 0) {
        subscriptionPrizeList.value.forEach(user => {
          if (user.status === 'BEAT' || user.status === 'COMPLETE') {
            const prizeUser = {
              uid: user.uid,
              닉네임: user.nick_name,
              등급: user.user_score?.tier?.tierName,
              전화번호: user.cell_num,
              청약일시: user.create_at,
              수령인이름: user.order_delivery?.name,
              주소: `${user.order_delivery?.address} ${user.order_delivery?.address_detail}`,
            };
            if (subscription.value.option_meta?.option_1) {
              prizeUser[
                `옵션(${subscription.value.option_meta.option_1.key})`
              ] = user.options.option_1;
            }
            if (subscription.value.option_meta?.option_2) {
              prizeUser[
                `옵션(${subscription.value.option_meta.option_2.key})`
              ] = user.options.option_2;
            }
            if (subscription.value.option_meta?.option_3) {
              prizeUser[
                `옵션(${subscription.value.option_meta.option_3.key})`
              ] = user.options.option_3;
            }
            prizeSheet.value.push(prizeUser);
          }
        });
        prizeSheets.value.push({
          name: '당첨자',
          data: prizeSheet.value,
        });
        const date = dateTimeHelper.timestampToDateTime(new Date());
        prizeSheetName.value = `${subscription.value.title}-당첨자-${
          date.split(' ')[0]
        }.xlsx`;
      }
    };

    const updateInvoice = async user => {
      const targetUsers = filteredData.value.filter(
        user => user.isCheck && user.order_delivery?.invoices?.[0],
      );

      if (targetUsers.length === 0) {
        await swal.alert('선택된 사용자가 없습니다.');
        return;
      }

      const results = {
        success: [],
        failed: [],
        total: targetUsers.length,
      };

      const invalidUsers = targetUsers.filter(user => {
        const invoice = user.order_delivery.invoices[0];
        const isEmpty =
          !invoice.data_id &&
          (!invoice.delivery_company_cd ||
            !invoice.invoice_number.toString().trim());
        return isEmpty && invoice.invoice_number.trim() !== '';
      });

      if (invalidUsers.length > 0) {
        const invalidMsg = invalidUsers
          .map(
            user =>
              `${user.nick_name}: 택배사 또는 송장번호가 입력되지 않았습니다.`,
          )
          .join('\n');
        await swal.alert(`다음 사용자의 정보를 확인해주세요.\n\n${invalidMsg}`);
        return;
      }

      try {
        store.commit('loading/showLoading', '배송정보 업데이트 중입니다.');

        await Promise.all(
          targetUsers.map(async user => {
            try {
              const invoice = user.order_delivery.invoices[0];

              if (invoice?.data_id) {
                // 송장번호가 비어있는 경우 - 삭제 처리
                if (invoice.invoice_number.trim() === '') {
                  const response = await apiService.delete(
                    `/usin-subscription-event-user-order-delivery-tracking/${invoice.data_id}`,
                  );

                  if (response.status) {
                    user.order_delivery.invoices[0].data_id = null;
                    results.success.push(user.nick_name);
                  } else {
                    results.failed.push({
                      name: user.nick_name,
                      error: '삭제 실패',
                    });
                  }
                  return;
                }

                // 송장 정보 수정
                const response = await apiService.put(
                  `/usin-subscription-event-user-order-delivery-tracking/${invoice.data_id}`,
                  {
                    delivery_company_cd: invoice.delivery_company_cd,
                    invoice_number: invoice.invoice_number.toString(),
                  },
                );

                if (response.status) {
                  results.success.push(user.nick_name);
                } else {
                  results.failed.push({
                    name: user.nick_name,
                    error: '배송정보 수정 실패',
                  });
                }
              } else {
                const response = await apiService.post(
                  '/usin-subscription-event-user-order-delivery-tracking',
                  {
                    order_delivery_data_id: user.order_delivery.data_id,
                    delivery_company_cd: invoice.delivery_company_cd,
                    invoice_number: invoice.invoice_number.toString(),
                  },
                );

                if (response.status) {
                  user.order_delivery.invoices[0].data_id =
                    response.data.data_id;
                  results.success.push(user.nick_name);
                } else {
                  results.failed.push({
                    name: user.nick_name,
                    error: response.message || '배송정보 등록 실패',
                  });
                }
              }
            } catch (error) {
              results.failed.push({
                name: user.nick_name,
                error: error.message,
              });
            }
          }),
        );

        let resultMsg = `${results.total}건 중 ${results.success.length}건 성공, ${results.failed.length}건 실패\n\n`;

        if (results.failed.length > 0) {
          resultMsg += '실패한 사용자:\n';
          results.failed.forEach(f => {
            resultMsg += `${f.name}\n`;
          });
        }

        await swal.success(resultMsg);

        filteredData.value.forEach(item => {
          item.isCheck = false;
          isAllCheck.value = !isAllCheck.value;
        });
      } finally {
        store.commit('loading/hideLoading');
      }
    };

    const updateDelivery = async () => {};
    const setAddPrize = async user => {
      if (prizingCount === subscription.value.subscribe_count) {
        swal.alert('추첨 인원이 초과하여 추첨을 할 수 없습니다.');
      } else {
        swal.prizeUserConfirm(async () => {
          store.commit('loading/showLoading', '발송중입니다.');
          const response = await apiService.patch(
            `/v1/usin-subscription-event/${id}/user/${user.data_id}/prize`,
          );
          store.commit('loading/hideLoading');
          swal.successConfirm(async () => {
            await setPrizeUser();
          }, '발송되었습니다.');
        }, `${user.nick_name}에게 당첨 메시지를 보내겠습니까?`);
      }
    };
    const extendTime = async user => {
      swal.confirm(
        async () => {
          store.commit('loading/showLoading', '정보 업데이트중입니다.');
          const response = await apiService.patch(
            `/v1/usin-subscription-event/${id}/user/${user.data_id}/extend-payment-close`,
          );
          store.commit('loading/hideLoading');
          swal.successConfirm(async () => {
            await setPrizeUser();
          }, '연장되었습니다.');
        },
        {
          title: `${user.nick_name}의 결제시간을 연장하시겠습니까?`,
          buttonText: '연장',
        },
      );
    };
    const cancelPayment = async user => {
      swal.inputConfirm(
        async text => {
          if (user.name === text) {
            store.commit('loading/showLoading', '결제 취소중입니다.');
            const response = await apiService.patch(
              `/v1/usin-subscription-event/${id}/user/${user.data_id}/cancel-payment`,
            );
            store.commit('loading/hideLoading');
            swal.successConfirm(async () => {
              await setPrizeUser();
            }, '취소되었습니다.');
          } else {
            swal.alert('이름이 틀렸습니다.');
          }
        },
        {
          title: `${user.name}의 결제를 취소하시겠습니까?`,
          text: '결제자의 이름을 입력해주세요.',
          buttonText: '결제취소',
        },
      );
    };
    const depositWithoutPassbook = async user => {
      swal.htmlConfirm(
        async result => {
          store.commit('loading/showLoading', '입금처리중입니다.');
          const response = await apiService.patch(
            `/v1/usin-subscription-event/${id}/user/${user.data_id}/deposit-without-passbook`,
            result,
          );
          store.commit('loading/hideLoading');
          swal.successConfirm(async () => {
            await setPrizeUser();
          }, '입금처리되었습니다.');
        },
        {
          title: `${user.name}의 입금처리 하시겠습니까?`,
          html: `
          <div class="form-group">
            <input id="name" type="text" class="form-control" placeholder="수령인 이름">
          </div>
          <div class="form-group">
            <input id="address" type="text" class="form-control" placeholder="주소(클릭해서 검색)" readonly>
            <input id="postCode" type="hidden" class="form-control" placeholder="상세주소">
          </div>
          <div class="form-group">
            <input id="addressDetail" type="text" class="form-control" placeholder="상세주소">
          </div>
          <div class="form-group">
            <input id="contact" type="text" class="form-control" placeholder="연락처(- 없이 숫자만)">
          </div>
        `,
          buttonText: '입금처리',
          didOpen: () => {
            document.querySelector('#address').addEventListener('click', () => {
              openSearchAddress();
            });
          },
          preConfirm: () => {
            if (
              document.querySelector('#name').value.trim() === '' ||
              document.querySelector('#address').value.trim() === '' ||
              document.querySelector('#addressDetail').value.trim() === '' ||
              document.querySelector('#postCode').value.trim() === '' ||
              document.querySelector('#contact').value.trim() === ''
            ) {
              // swal.alert('필수 정보값이 입력되지 않았습니다.');
              swal.showValidationMessage('필수 정보값이 입력되지 않았습니다.');
              return false;
            }
            return {
              name: document.querySelector('#name').value,
              address: document.querySelector('#address').value,
              address_detail: document.querySelector('#addressDetail').value,
              post_code: document.querySelector('#postCode').value,
              contact: document.querySelector('#contact').value,
            };
          },
        },
      );
    };
    const setPrizeUser = async () => {
      beatCount.value = 0;
      cancelCount.value = 0;
      outstandingCount.value = 0;
      completeCount.value = 0;
      const subscriptionUserData = await getSubscriptionPrizeUser(id);
      console.log('subscriptionUserData', subscriptionUserData);
      subscriptionPrizeList.value = subscriptionUserData;
      // setSheet(subscriptionUserData);
      // if (subscriptionUserData.length !== 0) {
      //   if (!(subscriptionUserData[0].status === 'READY' || subscriptionUserData[0].status === 'CANCEL')) {
      //
      //   }
      //   if (subscriptionUserData.prize_status === 'PRIZE_PROGRESS' || subscriptionUserData.prize_status === 'PRIZE_CLOSED') {
      //     hasPrize.value = true;
      //   }
      //   subscriptionPrizeList.value = [];
      //   /**
      //    * 총 신청자가 추첨수 3배수를 넘으면 3배수 신청자를 리스트에 담는다.
      //    */
      //   if (hasPrize.value === true && subscriptionUserData.length > subscription.value.subscribe_count * 3) {
      //     for (let i = 0; i < (subscription.value.subscribe_count * 3); i++) {
      //       subscriptionPrizeList.value.push(subscriptionUserData[i]);
      //     }
      //   } else {
      //     subscriptionPrizeList.value = subscriptionUserData;
      //   }
      // }
      prizingCount = 0;
      if (subscriptionPrizeList.value) {
        subscriptionPrizeList.value.forEach(user => {
          if (
            user.order_delivery &&
            user.order_delivery.invoices.length === 0
          ) {
            user.order_delivery.invoices.push({
              delivery_company_cd: '',
              invoice_number: '',
            });
          }
          // if (['BEAT', 'COMPLETE'].includes(user.status)) {
          //   prizingCount++;
          // }
          if (user.status === 'BEAT') {
            beatCount.value++;
            prizingCount++;
          }
          if (user.status === 'COMPLETE') {
            completeCount.value++;
            prizingCount++;
          }
          if (user.status === 'CANCEL') {
            cancelCount.value++;
          }
          if (user.status === 'OUTSTANDING') {
            cancelCount.value++;
          }
        });
        filteredData.value = subscriptionPrizeList.value;
        filterOption();
        if (prizingCount > 0) {
          setPrizeSheet();
        }
      }
    };
    const refreshPrizeUser = async () => {
      store.commit('loading/showLoading', '새로고침중입니다.');
      await setPrizeUser();
      store.commit('loading/hideLoading');
    };
    const deleteEvent = () => {
      swal.deleteConfirm(async () => {
        const response = await apiService.delete(
          `/v3/usin-subscription-event/${id}`,
        );
        if (response.status) {
          swal.deletedConfirm(() => {
            router.back();
          });
        } else {
          swal.alert(response.message);
        }
      }, `해당 청약 제품(${subscription.value.title})을 삭제하겠습니까?`);
    };
    const closeEvent = () => {
      swal.closeConfirm(async () => {
        const response = await apiService.patch(
          `/v1/usin-subscription-event/${id}/close-subscription`,
        );
        if (response.data.statusCode) {
          swal.successConfirm(async () => {
            const subscriptionData = await getSubscription(id);
            showThumbnailImage.value = true;
            subscription.value = subscriptionData;
          }, '해당 이벤트가 마감 처리되었습니다.');
        }
      }, `해당 청약 제품(${subscription.value.title})의 추첨을 마감하시겠습니까?`);
    };
    const copyUrl = async url => {
      try {
        await navigator.clipboard.writeText(url);
        swal.message('청약 제품 Content Url이<br>복사되었습니다.');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    };
    const filterOption = () => {
      filteredData.value = subscriptionPrizeList.value;
      if (option1.value !== '') {
        filteredData.value = filteredData.value.filter(
          user => user.options.option_1 === option1.value,
        );
      }
      if (option2.value !== '') {
        filteredData.value = filteredData.value.filter(
          user => user.options.option_2 === option2.value,
        );
      }
      if (option3.value !== '') {
        filteredData.value = filteredData.value.filter(
          user => user.options.option_3 === option3.value,
        );
      }
    };
    const resetFilter = () => {
      option1.value = '';
      option2.value = '';
      option3.value = '';
      filterOption();
    };
    const filterPrize = () => {
      showPrizeUser.value = !showPrizeUser.value;
      if (showPrizeUser.value) {
        filteredData.value = subscriptionPrizeList.value;
        filteredData.value = filteredData.value.filter(
          user => user.status === 'COMPLETE' || user.status === 'BEAT',
        );
      } else {
        filteredData.value = subscriptionPrizeList.value;
      }
    };
    /**
     * 청약몰 주소 입력을 위한 daum 주소 모듈 로딩
     */
    const loadDaumAddress = () => {
      const scriptTag = document.createElement('script');
      scriptTag.src =
        'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
      document.getElementsByTagName('body')[0].appendChild(scriptTag);
    };
    const openSearchAddress = () => {
      new daum.Postcode({
        oncomplete: data => {
          document.querySelector('#postCode').value = data.zonecode;
          document.querySelector('#address').value = data.address;
        },
      }).open();
    };
    const fileUploaded = event => {
      file.value = event.target.files ? event.target.files[0] : null;
    };
    const upload = () => {
      fileBtn.value.click();
    };
    const onParsed = data => {
      setDeliveryData(data);
    };
    const setDeliveryData = data => {
      const placingOrderDeleiveryList = [];
      subscriptionPrizeList.value.forEach(user => {
        if (user.status === 'COMPLETE') {
          const deliveryData = data.find(
            deliveryData =>
              user.cell_num === deliveryData['전화번호'] &&
              user.order_delivery.name === deliveryData['수령인'],
          );
          if (deliveryData) {
            user.order_delivery.invoices[0].delivery_company_cd =
              deliveryData['택배사'];
            user.order_delivery.invoices[0].invoice_number =
              deliveryData['송장번호'];
          }
        }
      });
      filteredData.value = subscriptionPrizeList.value;
    };
    const showDeliveryMessage = () => {
      const optionNames = '';
      const optionList = [];
      if (option1.value !== '') {
        optionList.push(option1.value);
      }
      if (option2.value !== '') {
        optionList.push(option2.value);
      }
      if (option3.value !== '') {
        optionList.push(option3.value);
      }
      const title =
        optionList.length === 0
          ? `${subscription.value.product_name} 제품의 발송일 안내 내용을 입력주세요.`
          : `${subscription.value.product_name} 제품(${optionList.join(
              ', ',
            )})의 발송일 안내 내용을 입력주세요.`;
      swal.htmlConfirm(
        async message => {
          store.commit(
            'loading/showLoading',
            '배송일정 메시지를 발송 중입니다.',
          );
          const response = await apiService.post(
            `/v1/usin-subscription-event/${id}/user/send-message/estimate-delivery-start`,
            {
              option_names: optionList,
              delivery_start_at: message,
            },
          );
          store.commit('loading/hideLoading');
          swal.successConfirm(async () => {}, '메시지가 발송 완료되었습니다.');
        },
        {
          // title: optionNames ? `${subscription.value.product_name}(${optionNames}) 제품의 발송일 안내 내용을<br>입력주세요.` : `${subscription.value.product_name} 제품의 발송일 안내 내용을<br>입력주세요.`,
          title,
          html: `
          <div class="form-group">
            <input id="delivery-message" type="text" class="form-control" placeholder="발송일 안내 내용" maxlength="30">
          </div>
        `,
          buttonText: '메시지 발송',
          preConfirm: () => {
            if (
              document.querySelector('#delivery-message').value.trim() === ''
            ) {
              // swal.alert('필수 정보값이 입력되지 않았습니다.');
              swal.showValidationMessage('안내 내용이 입력되지 않았습니다.');
              return false;
            }
            return document.querySelector('#delivery-message').value;
          },
        },
      );
    };
    const checkAll = () => {
      isAllCheck.value = !isAllCheck.value;
      filteredData.value.forEach(item => {
        item.isCheck = isAllCheck.value;
      });
    };
    const prizeCheckedUser = () => {
      const prizeUserDataList = [];
      filteredData.value.forEach(user => {
        if (user.isCheck && user.status === 'READY') {
          prizeUserDataList.push(user.data_id);
        } else {
          user.isCheck = false;
        }
      });
      if (prizeUserDataList.length === 0) {
        swal.alert('선택된 당첨자가 없습니다.');
        return false;
      }
      if (
        prizingCount + prizeUserDataList.length >
        subscription.value.subscribe_count
      ) {
        swal.alert('추첨 인원이 초과하여 추첨을 할 수 없습니다.');
        return false;
      }
      swal.prizeUserConfirm(async () => {
        store.commit('loading/showLoading', '발송중입니다.');
        const response = await apiService.patch(
          `/v1/usin-subscription-event/${id}/user/prize-bulk`,
          {
            user_data_ids: prizeUserDataList,
          },
        );
        store.commit('loading/hideLoading');
        swal.successConfirm(async () => {
          isAllCheck.value = false;
          filteredData.value.forEach(item => {
            item.isCheck = isAllCheck.value;
          });
          await setPrizeUser();
        }, '발송되었습니다.');
      }, `${prizeUserDataList.length}명에게 당첨 메시지를 보내겠습니까?`);
    };
    const updateCheckDelivery = () => {
      const deliveryUserDataList = [];
      try {
        filteredData.value.forEach(user => {
          if (
            user.isCheck &&
            user.status === 'COMPLETE' &&
            !user.order_delivery.invoices[0].data_id
          ) {
            const invoice = user.order_delivery.invoices[0];
            if (
              invoice.delivery_company_cd === '' ||
              invoice.invoice_number === ''
            ) {
              throw new Error();
            }
            deliveryUserDataList.push({
              order_delivery_data_id: user.order_delivery.data_id,
              delivery_company_cd: invoice.delivery_company_cd,
              invoice_number: invoice.invoice_number.toString(),
            });
          } else {
            user.isCheck = false;
          }
        });
      } catch (error) {
        swal.alert('배송정보가 누락되었습니다.');
        return false;
      }
      if (deliveryUserDataList.length === 0) {
        swal.alert('선택된 당첨자가 없습니다.');
        return false;
      }
      swal.prizeUserConfirm(async () => {
        store.commit('loading/showLoading', '발송중입니다.');
        await apiService.post(
          '/usin-subscription-event-user-order-delivery-tracking/bulk',
          {
            delivery_trackings: deliveryUserDataList,
          },
        );
        store.commit('loading/hideLoading');
        swal.successConfirm(async () => {
          isAllCheck.value = false;
          filteredData.value.forEach(item => {
            item.isCheck = isAllCheck.value;
          });
          await setPrizeUser();
        }, '등록되었습니다.');
      }, `${deliveryUserDataList.length}명의 배송정보를 등록하시겠습니까?`);
    };
    return {
      id,
      isDetail,
      subscription,
      thumbnailFile,
      thumbnailImg,
      showThumbnailImage,
      detailFile,
      detailImg,
      detailImageList,
      back,
      subscriptionUserList,
      subscriptionPrizeList,
      openUserModal,
      closeModal,
      templateBody,
      userModal,
      // 청약신청자 다운로드
      sheets,
      sheetName,
      downloadBtn,
      download,
      setPrize,
      hasPrize,
      updateInvoice,
      setAddPrize,
      prizeSheets,
      prizeSheetName,
      prizeDownloadBtn,
      downloadPrize,
      refreshPrizeUser,
      deleteEvent,
      closeEvent,
      copyUrl,
      extendTime,
      cancelPayment,
      option1List,
      option2List,
      option3List,
      option1,
      option2,
      option3,
      filterOption,
      filteredData,
      resetFilter,
      depositWithoutPassbook,
      filterPrize,
      showPrizeUser,
      file,
      fileBtn,
      fileUploaded,
      upload,
      onParsed,
      beatCount,
      cancelCount,
      outstandingCount,
      completeCount,
      showDeliveryMessage,
      isAllCheck,
      checkAll,
      prizeCheckedUser,
      updateCheckDelivery,
    };
  },
};
</script>

<style scoped>
.thumbnail-img {
  width: 300px;
  height: 300px;
}
.detail-img {
  width: 200px;
}
.showModal {
  display: block;
}
.modal_background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
}
.prize-modal {
  max-width: 80%;
}
.cancel-line {
  text-decoration: line-through;
}
input[type='checkbox'] {
  -ms-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
}
</style>
