import { BaseV2Model } from './base-v2.model';

class PartnersModel extends BaseV2Model {
  protected collectionName = 'v3/partners/member';

  protected keepCollectionName = true;

  private static _instance: PartnersModel;

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }
}

export default PartnersModel.Instance;
