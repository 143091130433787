
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import dateTimeHelper from '../../helper/datetime.helper';
import { default as TierUsinModel } from '../../models/tierUsin.model';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import swal from '../../helper/swal.helper';

export default defineComponent({
  components: {
    SubmitButton,
  },
  setup() {
    const router = useRouter();
    let isSubmitting = ref(false);

    const user = ref<any>({
      id: '',
      uid: '',
      nick_name: '',
      expiredAt: '2023-06-19 20:00:00',
      createdBy: '',
      createdAt: '2023-06-19 20:00:00',
      remark: '',
      personal_info: {
        name: '',
        cell_num: '',
      },
    });
    onMounted(async () => {
      if (router.currentRoute.value.params.id) {
        const findUser = await TierUsinModel.findById(
          router.currentRoute.value.params.id,
        );
        user.value = {
          ...findUser.item,
          createdAt: dateTimeHelper.timestampToDateTime(
            findUser.item.createdAt,
            'YYYY-MM-DD',
          ),
          expiredAt: dateTimeHelper.timestampToDateTime(
            findUser.item.expiredAt,
            'YYYY-MM-DD',
          ),
        };
        console.log(user.value);
      }
    });

    async function updateUsinUser() {
      swal.alert('기능 개발은 되었지만 검증 되지 않아서 사용하실 수 없습니다.');

      // swal.updateConfirm(async () => {
      //   const updateResult = await TierUsinModel.update(user.value.id, {
      //     expired: user.value.expiredAt,
      //     remark: user.value.remark,
      //   });
      //   if (updateResult.status) {
      //     router.push('/usin-user-management');
      //   } else {
      //     swal.alert('수정에 실패하였습니다.');
      //   }
      // });
    }
    async function deleteUsinUser() {
      swal.alert('기능 개발은 되었지만 검증 되지 않아서 사용하실 수 없습니다.');
      // swal.deleteConfirm(async () => {
      //   const updateResult = await TierUsinModel.delete(user.value.id);
      //   if (updateResult) {
      //     router.push('/usin-user-management');
      //   } else {
      //     swal.alert('삭제에 실패하였습니다.');
      //   }
      // });
    }

    return {
      user,
      isSubmitting,
      dateTimeHelper,
      updateUsinUser,
      deleteUsinUser,
    };
  },
});
