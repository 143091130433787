
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
// eslint-disable-next-line import/no-named-default
import { default as TierUsinModel } from '../../models/tierUsin.model';
import dateTimeHelper from '../../helper/datetime.helper';
import numberHelper from '../../helper/number.helper';
import { constants } from '../../config/constant.config';
import swal from '@/helper/swal.helper';

export default defineComponent({
  name: 'UsertList',
  methods: {},
  setup: () => {
    const router = useRouter();
    const perPage = constants().DEFAULT_PERPAGE;
    const loading = ref(false);
    const uid = ref('');
    const expiredAt = ref('');
    const filter = ref({
      nick_name: '',
      email_address: '',
      is_terminated: null,
    });
    const users = ref<Array<any>>([]);

    onMounted(async () => {
      isLoading(true);
      await loadData();
    });

    async function loadData() {
      const response = await TierUsinModel.getAllNoPagination([
        ['useYn', 'Y'],
        ['isNotExpired', 'Y'],
      ]);
      users.value = response.items;
      isLoading(false);
    }

    function isLoading(value: boolean) {
      loading.value = value;
    }

    async function addTierUsin() {
      swal.alert('기능 개발은 되었지만 검증 되지 않아서 사용하실 수 없습니다.');
      // if (uid.value.trim() === '' || expiredAt.value.trim() === '') {
      //   swal.alert('어신등급 종료 일시, UID를 입력해주세요.');
      // }
      // isLoading(true);
      // await TierUsinModel.create({
      //   uid: uid.value,
      //   expired: expiredAt.value,
      // });
      // loadData();
    }

    async function editUser(id: any) {
      router.push(`/usin-user-management/edit/${id}`);
    }
    return {
      users,
      dateTimeHelper,
      numberHelper,
      perPage,
      loading,
      TierUsinModel,
      filter,
      uid,
      expiredAt,
      addTierUsin,
      editUser,
    };
  },
});
