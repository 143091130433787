
import { ref, toRefs, watch, defineComponent, onMounted, PropType } from 'vue';
import { constants } from '../../config/constant.config';
import { BaseModel } from '../../models/base.model';

export default defineComponent({
  name: 'Pagination',
  emits: ['dataChanged', 'isLoading'],
  props: {
    dataModel: {
      type: BaseModel,
      required: true,
    },
    filterConditions: {
      type: Object as PropType<any>,
      required: false,
      default: function() {
        return {};
      },
    },
    orderOptions: {
      type: Array,
      required: false,
      default: function() {
        return [];
      },
    },
    limit: {
      type: Number,
      required: false,
      default: constants().DEFAULT_PERPAGE,
    },
  } as any,
  setup: (props, { emit }) => {
    const { dataModel, filterConditions, orderOptions, limit } = toRefs(props);
    let dataItems: any = {};
    let refListData = ref<any>({});
    let totalPage = ref(0);
    let currentPage = ref(1);
    let indexCounter = 1;
    let disablePrev = ref(true);
    let disableNext = ref(false);

    onMounted(async () => {
      // Get list data
      await loadData();
    });

    watch(
      [filterConditions, orderOptions],
      () => {
        indexCounter = 1;
        loadData();
      },
      {
        deep: true,
      },
    );

    async function loadData(page?: any) {
      emit('isLoading', true);
      dataItems = await dataModel.value.getAll(
        filterConditions.value,
        orderOptions.value,
        page,
        limit.value,
      );
      if (dataItems.length === 0 || dataItems.length < limit.value) {
        if (page > 1) {
          disableNext.value = true;
          disablePrev.value = false;
        } else {
          disablePrev.value = true;
          disableNext.value = false;
        }
      }

      if (typeof page !== 'undefined') {
        indexCounter = page * limit.value - limit.value + 1;
      }

      dataItems.forEach((value: any, key: any) => {
        dataItems[key].indexCounter = indexCounter++;
      });

      refListData.value = dataItems;
      emit('dataChanged', refListData.value);
      emit('isLoading', false);
    }

    function nextClick() {
      if (!disableNext.value) {
        disablePrev.value = false;
        currentPage.value += 1;
        loadData(currentPage.value);
      }
    }

    function prevClick() {
      if (!disablePrev.value) {
        disablePrev.value = false;
        disableNext.value = false;
        currentPage.value -= 1;
        if (currentPage.value == 1) {
          disablePrev.value = true;
        }
        loadData(currentPage.value);
      }
    }

    return {
      totalPage,
      currentPage,
      nextClick,
      prevClick,
      disableNext,
      disablePrev,
    };
  },
});
