
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import markerSDK from '@marker.io/browser';

const defaultLayout = 'default';

export default defineComponent({
  setup() {
    const { currentRoute } = useRouter();

    const layout = computed(
      () => `${currentRoute.value.meta.layout || defaultLayout}-layout`,
    );

    onMounted(async () => {
      try {
        // const widget = await markerSDK.loadWidget({
        //   project: '677e04bb18fd1ef339b1d120',
        // });
        // // 필요한 경우 추가 설정
        // widget.setReporter({
        //   email: 'owen@sgma.io',
        //   fullName: '오언',
        // });
      } catch (error) {
        console.error('Failed to load Marker.io widget:', error);
      }
    });

    return {
      layout,
      currentRoute,
    };
  },
});
