import { BaseV2Model } from '@/models/base-v2.model';

export interface TierUsin {
  id: string;
  uid: string;
  nick_name: string;
  expiredAt: any;
  createdBy: string;
  createdAt: any;
  remark: string;
  personal_info?: PersonalInfo;
  address_list: Array<AddressInfo>;
}

export type PersonalInfo = {
  name: string;
  cell_num: string;
};

export type AddressInfo = {
  address: number;
  create_at: string;
};

class TierUsinModel extends BaseV2Model {
  protected collectionName = 'tier-usin';

  private static _instance: TierUsinModel;

  protected keepCollectionName = true;

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }
}

export default TierUsinModel.Instance;
